(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/service-payment/assets/payment-service.js') >= 0) return;  svs.modules.push('/components/payment/service-payment/assets/payment-service.js');

'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
function _asyncIterator(r) { var n, t, o, e = 2; for ("undefined" != typeof Symbol && (t = Symbol.asyncIterator, o = Symbol.iterator); e--;) { if (t && null != (n = r[t])) return n.call(r); if (o && null != (n = r[o])) return new AsyncFromSyncIterator(n.call(r)); t = "@@asyncIterator", o = "@@iterator"; } throw new TypeError("Object is not async iterable"); }
function AsyncFromSyncIterator(r) { function AsyncFromSyncIteratorContinuation(r) { if (Object(r) !== r) return Promise.reject(new TypeError(r + " is not an object.")); var n = r.done; return Promise.resolve(r.value).then(function (r) { return { value: r, done: n }; }); } return AsyncFromSyncIterator = function (r) { this.s = r, this.n = r.next; }, AsyncFromSyncIterator.prototype = { s: null, n: null, next: function () { return AsyncFromSyncIteratorContinuation(this.n.apply(this.s, arguments)); }, return: function (r) { var n = this.s.return; return void 0 === n ? Promise.resolve({ value: r, done: !0 }) : AsyncFromSyncIteratorContinuation(n.apply(this.s, arguments)); }, throw: function (r) { var n = this.s.return; return void 0 === n ? Promise.reject(r) : AsyncFromSyncIteratorContinuation(n.apply(this.s, arguments)); } }, new AsyncFromSyncIterator(r); }
const {
  Payable,
  logger
} = svs.components.payment.common;
const {
  PaymentError,
  BaseError,
  CollectionError
} = svs.components.payment.common.error;
const {
  eventNames,
  publishEvent
} = svs.components.lbUtils.onEvents;
const {
  NotImplementedError
} = svs.components.payment.common.error;

var _config = new WeakMap();
class PaymentService {
  constructor(newConfig) {
    _classPrivateFieldInitSpec(this, _config, void 0);
    _classPrivateFieldSet(_config, this, Object.freeze(Object.assign({
      haltPaymentOnException: true
    }, newConfig)));
  }

  assertPayable() {
    for (var _len = arguments.length, items = new Array(_len), _key = 0; _key < _len; _key++) {
      items[_key] = arguments[_key];
    }
    for (const item of items) {
      if (!(item instanceof Payable)) {
        throw new PaymentError({
          clientCode: PaymentError.ClientErrorCodes.NOT_PAYABLE
        });
      }
    }
  }

  async initiatePaymentRequest(payableItems) {
    const paymentResponses = [];
    const failedPayableItems = [];
    let count = 0;
    const collectionError = new CollectionError();
    var _iteratorAbruptCompletion = false;
    var _didIteratorError = false;
    var _iteratorError;
    try {
      for (var _iterator = _asyncIterator(payableItems), _step; _iteratorAbruptCompletion = !(_step = await _iterator.next()).done; _iteratorAbruptCompletion = false) {
        const payable = _step.value;
        {
          try {
            count++;
            const payableResponse = await this.processRequest(payable);
            paymentResponses.push({
              payable,
              dto: payableResponse
            });
            logger.info("S! P:".concat(count, "(").concat(payableItems.length, ") tId:").concat(payableResponse.transactionId, " ").concat(payable));

            publishEvent(eventNames.PAYMENT_SUCCESS, {
              detail: {
                payable,
                dto: payableResponse
              }
            });
          } catch (error) {
            var _error$severity, _svs;
            const logLevel = (_error$severity = error.severity) !== null && _error$severity !== void 0 ? _error$severity : 'warn';
            const errorMessage = error instanceof BaseError ? error.toString() : error.message;
            logger[logLevel]("Payment failed at ".concat(count, "(").concat(payableItems.length, "): ").concat(errorMessage).concat(logLevel === 'warn' ? " ".concat((_svs = svs) === null || _svs === void 0 || (_svs = _svs.core) === null || _svs === void 0 || (_svs = _svs.jupiterFetch) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 ? void 0 : _svs.url) : ''));
            failedPayableItems.push({
              payable,
              error
            });
            collectionError.addError(error);
            if (this.config.haltPaymentOnException) {
              break;
            }
          }
        }
      }

    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (_iteratorAbruptCompletion && _iterator.return != null) {
          await _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
    const remainingPayableItems = failedPayableItems.map(_ref => {
      let {
        payable
      } = _ref;
      return payable;
    }).concat(payableItems.remaining);
    let failCount = payableItems.length - remainingPayableItems.length;
    for (const unpaidPayable of remainingPayableItems) {
      failCount++;
      logger.info("F! P:".concat(failCount, "(").concat(payableItems.length, ") ").concat(unpaidPayable));
      count++;
    }
    return [paymentResponses, failedPayableItems.length ? collectionError : null];
  }

  processRequest() {
    throw new NotImplementedError();
  }

  onPaymentDone() {
    throw new NotImplementedError();
  }

  trackPayments() {
    throw new NotImplementedError();
  }

  get config() {
    return _classPrivateFieldGet(_config, this);
  }
}
setGlobal('svs.components.payment.servicePayment.PaymentService', PaymentService);

 })(window);